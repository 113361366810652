import * as yup from "yup";

export const useRegisterValidation = () => {
  return yup.object().shape({
    lastName: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .min(3, "Το Επώνυμο πρέπει να αποτελείται το λιγότερο από 3 χαρακτήρες."),

    firstName: yup
      .string()
      .required("Το πεδίο  είναι υποχρεωτικό")
      .min(3, "Το Όνομα πρέπει να αποτελείται το λιγότερο από 3 χαρακτήρες."),

    fatherName: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .min(
        3,
        "Το Πατρώνυμο πρέπει να αποτελείται το λιγότερο από 3 χαρακτήρες."
      ),

    afm: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .length(9, "Ο ΑΦΜ πρέπει να αποτελείται από 9 χαρακτήρες."),

    amka: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .length(11, "O AMKA πρέπει να αποτελείται από 11 χαρακτήρες."),

    instituteScheme: yup
      .string()
      .oneOf(
        ["Φορέας", "Ένωση Φορέων"],
        "Η τιμή πρέπει να είναι μία από τις επιτρεπόμενες"
      )
      .required("Το πεδίο είναι υποχρεωτικό"),

    institute: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .min(1, "Το πεδίο πρέπει να αποτελείται από 1 έως 250 χαρακτήρες.")
      .max(250, "Το πεδίο πρέπει να αποτελείται από 1 έως 250 χαρακτήρες."),

    address: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .min(3, "Το πεδίο πρέπει να αποτελείται από 3 έως 100 χαρακτήρες.")
      .max(100, "Το πεδίο πρέπει να αποτελείται από 3 έως 100 χαρακτήρες."),

    city: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .min(3, "Το πεδίο πρέπει να αποτελείται από 3 έως 30 χαρακτήρες.")
      .max(30, "Το πεδίο πρέπει να αποτελείται από 3 έως 30 χαρακτήρες."),

    zip: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .length(5, "Το πεδίο πρέπει να αποτελείται από 5 χαρακτήρες."),

    email: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .email("Το email δεν είναι έγκυρο."),

    mobile: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .length(10, "Το πεδίο πρέπει να αποτελείται από 10 χαρακτήρες."),

    comments: yup
      .string()
      .max(500, "Το πεδίο πρέπει να αποτελείται έως 500 χαρακτήρες."),
  });
};
