import { AttachFile } from "@mui/icons-material";
import { useState } from "react";

const History = ({ history, handlePreviewFile }) => {
  const [data, setData] = useState(history);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const sortTable = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    let sortedData;
    if (["ageRange", "gender"].includes(key)) {
      sortedData = [...data].sort((a, b) => {
        const aValue = a[key]?.name || "";
        const bValue = b[key]?.name || "";
        if (aValue < bValue) {
          return direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    } else {
      sortedData = [...data].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setData(sortedData);
  };

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <i className="fas fa-sort-up"></i>;
      } else {
        return <i className="fas fa-sort-down"></i>;
      }
    } else {
      return <i className="fas fa-sort"></i>;
    }
  };

  return (
    <div className="history-container">
      <div className="history-header">Ιστορικό</div>
      <div className="history-table-container">
        <table className="application-input-table">
          <thead>
            <tr>
              <th onClick={() => sortTable("logShortFormatted")}>
                Ημερομηνία {getIcon("logFormatted")}
              </th>
              <th onClick={() => sortTable("action")}>
                Ενέργεια {getIcon("action")}
              </th>
              <th onClick={() => sortTable("fullname")}>
                Χρήστης {getIcon("fullname")}
              </th>
              <th>Αρχεία</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index}>
                <td>{row?.logFormatted}</td>
                <td>{row?.action}</td>
                <td>{row?.fullName}</td>
                <td align="center">
                  {row?.files?.map((file, index) => (
                    <p
                      key={index}
                      onClick={() => {
                        handlePreviewFile(file);
                      }}
                      style={{
                        color: "#387de7",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: 3,
                      }}
                    >
                      {index + 1}
                      <AttachFile sx={{ fontSize: 20 }} />
                    </p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default History;
