import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FileListItemProps, FileListItemHandle } from "../types";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  DragIndicator,
  Delete,
  ChevronRight,
  WarningRounded,
  AttachFile,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  useMemo,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSnackbar } from "notistack";
import ShortPreview from "./ShortPreview";
import { useFileContext } from "context/FilesV2";

const FileListItem = forwardRef<FileListItemHandle, FileListItemProps>(
  (props, ref) => {
    const { file, onRemove, onPreview, onEdit, isReadOnly, allExpansion } =
      props;
    const { control } = useFileContext();
    const { enqueueSnackbar } = useSnackbar();
    const [openShortPreview, setOpenShortPreview] = useState<boolean>(false);

    let name;
    let ext;
    try {
      const parts = file.name.split(".");
      if (parts.length > 1) {
        ext = parts.pop(); // Extract the last part as the extension
        name = parts.join("."); // Join the remaining parts as the name
      } else {
        name = file.name; // No extension found
        ext = ""; // No extension
      }
    } catch (e) {
      console.log(e);
    }

    /* const isError = useMemo(() => {
            return (
                aspectRatio &&
                file?.aspectRatio &&
                file?.aspectRatio.toFixed(2) !== aspectRatio.toFixed(2)
            );
        }, [file?.aspectRatio, aspectRatio]); */
    // const isError = file?.error ? true : false;

    const hendleGetFileObect = async () => {
      try {
        if (!file.fileObject) {
          const fileObject = await control.getFileObject(file.id);
          control.updateFile(file.id, { ...file, fileObject: fileObject });
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      hendleGetFileObect();
    }, []);

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: file.id,
      });

    const diaplayName = useMemo(() => {
      if (file?.name && file.name.length > 30) {
        return file.name.substring(0, 30) + "...";
      }
      return file?.name ?? "-";
    }, [file?.name]);

    useEffect(() => {
      if (!!file.error) {
        enqueueSnackbar(
          <Stack>
            <Typography>{file.name}</Typography>
            <Typography>- Aspect ratio mismatch</Typography>
          </Stack>,
          {
            variant: "error",
          }
        );
      }
    }, [file?.error]);

    const handleShortPreview = () => {
      setOpenShortPreview((prev) => !prev);
    };

    useEffect(() => {
      if (allExpansion) {
        setOpenShortPreview(true);
      } else {
        setOpenShortPreview(false);
      }
    }, [allExpansion]);

    useImperativeHandle(ref, () => ({
      expand() {
        setOpenShortPreview(true);
      },
      collapse() {
        setOpenShortPreview(false);
      },
    }));

    return (
      <Box
        sx={{
          border: "1px solid #4d79bc",
          background: !!file?.error ? "#ffd6d6" : "#fafafa",
          transform: CSS.Transform.toString(transform),
          transition: transition,
          borderRadius: 2,
          px: 1,
          mb: 1,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ width: "100%" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Box
              ref={setNodeRef}
              {...(!isReadOnly && attributes)}
              {...(!isReadOnly && listeners)}
              sx={{ pt: 1 }}
            >
              <DragIndicator sx={{ color: "#7c94b8" }} />
            </Box>
            {!["xlsx", "xls"].includes(ext ?? "") && (
              <IconButton
                onClick={handleShortPreview}
                disabled={!file.fileObject}
              >
                {openShortPreview ? (
                  <VisibilityOff
                    sx={{
                      color: "#4d79bc",
                      // transform: openShortPreview ? 'rotate(90deg)' : ''
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#4d79bc",
                      // transform: openShortPreview ? 'rotate(90deg)' : ''
                    }}
                  />
                )}
              </IconButton>
            )}
            <AttachFile
              fontSize="small"
              sx={{
                color: !!file?.error ? "#a81323" : "#387de7",
              }}
            />
            {file?.name && file.name.length > 30 ? (
              <Tooltip title={file?.name}>
                <Typography
                  onClick={onPreview}
                  sx={{
                    cursor: "pointer",
                    color: !!file?.error ? "#a81323" : "#387de7",
                    ":hover": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <u>{diaplayName}</u>
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                onClick={onPreview}
                sx={{
                  cursor: "pointer",
                  color: !!file?.error ? "#a81323" : "#387de7",
                  ":hover": {
                    fontWeight: "bold",
                  },
                }}
              >
                <u>{diaplayName}</u>
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            {!!file?.error && (
              <Tooltip title={file?.error}>
                <IconButton onClick={isReadOnly ? onPreview : onEdit}>
                  <WarningRounded
                    sx={{
                      color: "#ff5a00",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {file.fileObject &&
              !isReadOnly &&
              file.fileObject?.type.startsWith("image") && (
                <IconButton onClick={isReadOnly ? onPreview : onEdit}>
                  <Edit sx={{ color: "#4d79bc" }} />
                </IconButton>
              )}
            {file.fileObject && !isReadOnly && (
              <IconButton onClick={onRemove}>
                <Delete sx={{ color: "#d12639" }} />
              </IconButton>
            )}
            {!file.fileObject && (
              <Typography variant="overline" mr={1}>
                {"Φόρτωση "}
              </Typography>
            )}
            {!file.fileObject && (
              <CircularProgress size={24} sx={{ color: "#4d79bc" }} />
            )}
          </Stack>
        </Stack>
        {file?.fileObject && (
          <ShortPreview open={openShortPreview} src={file.fileObject} />
        )}
      </Box>
    );
  }
);

export default FileListItem;
