import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useTheme } from "@emotion/react";
import { WarningOutlined } from "@mui/icons-material";

const DynamicDialog = (props) => {
  const {
    open,
    onClose,
    onAccept,
    meta,
    content,
    type,
    hideSecondaryButton = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const methods = useFormContext();
  const theme = useTheme();

  const DialogIcon = () => {
    if (type === "error")
      return <CloseIcon sx={{ fontSize: 50, color: "#c42c0e" }} />;
    if (type === "warning")
      return (
        <WarningOutlined
          sx={{ fontSize: 50, color: "#F7C752", padding: 0.4 }}
        />
      );
    return (
      <InfoIcon sx={{ fontSize: 50, color: theme.palette.success.main }} />
    );
  };

  const iconBgcolor = () => {
    switch (type) {
      case "error":
        return "#ffe6e6";
      case "warning":
        return "#FEF7D1";
      case "success":
        return "#DAF7A6";
      default:
        return theme?.palette?.primary?.main;
    }
  };

  const titleColor = () => {
    switch (type) {
      case "error":
        return "error";
      case "warning":
        return "#755118";
      case "success":
        return theme?.palette?.success?.main;
      default:
        return theme?.palette?.primary?.main;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>
        <Typography
          varianh="h3"
          color={titleColor()}
          sx={{ flexGrow: 1, fontWeight: "bold" }}
        >
          {meta?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={4}
          sx={{ mb: 0 }}
        >
          <Box sx={{ p: 1, borderRadius: 50 }}>
            <DialogIcon />
          </Box>
          <DialogContentText>
            <Box
              sx={{
                p: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              <Typography>{meta?.description ?? t("UnknownError")}</Typography>
            </Box>
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={meta?.actionIcon}
          variant="contained"
          onClick={onAccept}
          color="primary"
        >
          {t(meta?.action) ?? t("OK")}
        </Button>
        {!hideSecondaryButton && (
          <Button
            sx={{ color: "#fefefe" }}
            startIcon={meta?.cancelIcon}
            variant="contained"
            onClick={onClose}
            color={meta?.cancelColor ?? "error"}
          >
            {t(meta?.cancel) ?? t("Cancel")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DynamicDialog;
