import axiosInstance from "../axiosInstance";

export const applicationsApi = {
  post: (path, params, headers, cancelToken) =>
    axiosInstance
      .post(`/Register${path}`, params, {
        cancelToken: cancelToken?.token,
        headers,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
        // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
  get: (path, params, headers, cancelToken) =>
    axiosInstance
      .get(
        `/Applications${path}`,
        { params },
        {
          cancelToken: cancelToken?.token,
          headers,
        }
      )
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
};

export const registerForm = async (params) =>
  applicationsApi.post("/RegisterForm", JSON.stringify(params), {
    "Content-Type": "application/json",
  });
