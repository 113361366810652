import { FileGroupProps, FileGroupHandle } from "./types";
import FileInput from "./FileInput";
import {
  Box,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Add, DownloadForOfflineOutlined } from "@mui/icons-material";
import { useFileGroup } from "./hooks";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import { IdentifiedFile } from "context/FilesV2/types";
import { createMarkup } from "util/createMarkup";

const FileGroup = forwardRef<FileGroupHandle, FileGroupProps>((props, ref) => {
  const { t } = useTranslation();
  const {
    id,
    title,
    bundles,
    maxBundles,
    isReadOnly,
    onUpload,
    onAddBundle,
    onRemoveBundle,
    onAddFile,
    onRemoveFile,
    templates,
    canAdd = true,
    ...fileInputProps
  } = props;
  const {
    addBundle,
    openRemoveDialog,
    closeRemoveDialog,
    removeBundle,
    upload,
    downloadTemplate,
    openDialog,
    fileInputRefs,
    fgRef,
  } = useFileGroup(props, ref);

  return (
    <>
      <Box ref={fgRef}>
        <div dangerouslySetInnerHTML={createMarkup(title)} />
        {templates && templates.length > 0 && (
          <Stack spacing={1} sx={{ mt: 1, mb: 3 }}>
            {templates.map((template) => (
              <Stack
                key={template.id}
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "#4d79bc",
                  ":hover": {
                    color: "#79AEC9",
                  },
                  cursor: "pointer",
                }}
                onClick={() => downloadTemplate(template.id, template.filename)}
              >
                <DownloadForOfflineOutlined fontSize="small" />
                <Typography>
                  <u>{template.title}</u>
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {!isReadOnly &&
          (maxBundles ?? 0) > (bundles?.length ?? 0) &&
          canAdd && (
            <Button
              variant="contained"
              sx={{
                background: "#4d79bc",
                color: "#fff",
                ":hover": {
                  background: "#79AEC9",
                  color: "#fff",
                },
              }}
              size="small"
              onClick={addBundle}
              startIcon={<Add />}
            >
              {t("AddFile")}
            </Button>
          )}
        <Stack spacing={3} sx={{ mt: 1, mb: 3 }}>
          {(!bundles || (bundles && bundles?.length === 0)) && (
            <Typography variant="body2" color="textSecondary">
              - {t("FilesNotFound")}
            </Typography>
          )}
          {bundles &&
            bundles?.map((bundle, index) => (
              <FileInput
                key={bundle.id}
                ref={(el) => (fileInputRefs.current[index] = el)}
                bundle={bundle}
                onRemove={() => openRemoveDialog(bundle.id)}
                onUpload={async (files: IdentifiedFile[], fileForm: any) => {
                  await upload(files, fileForm, bundle?.id);
                  return true;
                }}
                isReadOnly={isReadOnly}
                canAdd={canAdd}
                {...fileInputProps}
              />
            ))}
        </Stack>
      </Box>
      <Dialog open={openDialog} onClose={closeRemoveDialog}>
        <DialogTitle>
          <Typography>{t("Warning")}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{t("AreYouSureRemoveBundle")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={closeRemoveDialog}
            sx={{
              color: "#4d79bc",
              ":hover": {
                background: "#79AEC9",
                color: "#fff",
              },
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={removeBundle}
            sx={{
              background: "#c42c0e",
              color: "#fff",
              ":hover": { background: "#c9807d", color: "#fff" },
            }}
          >
            {t("Remove")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default FileGroup;
