import { Login } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./index.css";
import { useLoading } from "hooks/useLoading";

const RegisterStep1 = () => {
  const { loading, setLoading } = useLoading();
  const startOAuthChallenge = () => {
    setLoading(true);
    try {
      const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
      const fullUrl = `${baseUrl}/auth/GoGsis`;
      window.location.href = fullUrl;
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <h3 className="auth-required-text">Απαιτείται ταυτοποίηση</h3>
      <p style={{ fontSize: "1.25em", marginTop: 48, marginBottom: 24 }}>
        Για να προχωρήσετε, πρέπει πρώτα να συνδεθείτε με κωδικούς TAXISNET.
      </p>
      <div>
        <Button
          startIcon={<Login />}
          variant="contained"
          color="secondary"
          sx={{ color: "#fefefe" }}
          onClick={startOAuthChallenge}
        >
          Συνέχεια στο TAXISNET
        </Button>
      </div>
    </div>
  );
};

export default RegisterStep1;
