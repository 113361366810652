import { AttachFile } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import FilePreviewer from "qubCommon/FilePreviewer";

const ListFileItem = ({
  index,
  file,
  handlePreviewFile = () => {},
  id,
  bundleId,
  handleDeleteAndRemoveFile = () => {},
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ width: "100%" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center">
        <AttachFile
          fontSize="small"
          sx={{
            color: "#387de7",
          }}
        />
        <Typography
          onClick={handlePreviewFile}
          sx={{
            cursor: "pointer",
            color: "#387de7",
            ":hover": {
              fontWeight: "bold",
            },
          }}
        >
          {index + 1}. <u>{file?.origFName}</u>
        </Typography>
      </Stack>

      {/* <IconButton
          onClick={() => {
            handleDeleteAndRemoveFile(file, id, bundleId);
          }}
        >
          <Delete sx={{ color: "#d12639" }} />
        </IconButton> */}
    </Stack>
  );
};

export default ListFileItem;
