import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const GsisCallback = () => {
  const [data] = useState(window.serverData || {});
  const navigate = useNavigate();

  useEffect(() => {
    if (data.status === "success") {
      navigate("/amka-login");
    } else {
      navigate("/error");
    }
  }, [data]);

  return (
    <div>
      <p>Processing, please wait...</p>
    </div>
  );
};

export default GsisCallback;
