import { Alert, Collapse, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { createMarkup } from "src/util/createMarkup";
import CloseIcon from "@mui/icons-material/Close";

const AlertBanner = ({
  canClose = true,
  info,
  openInfo = true,
  setOpenMessage = () => {},
  children,
}) => {
  const [open, setOpen] = useState(openInfo);

  useEffect(() => {
    if (openInfo) setOpen(true);
    else setOpen(false);
  }, [openInfo]);

  return (
    <Collapse in={open}>
      <Alert
        action={
          canClose ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                if (canClose) {
                  setOpen(false);
                  if (setOpenMessage) setOpenMessage("");
                }
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        variant="filled"
        className={info?.cssClass}
        severity={info?.cssClass}
      >
        <div dangerouslySetInnerHTML={createMarkup(info?.message)} />
        {children}
      </Alert>
    </Collapse>
  );
};
export default AlertBanner;

AlertBanner.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
    cssClass: PropTypes.string,
  }),
};
