import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Stack,
  Grid,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import RowLabel from "./RowLabel";
import ColumnLabel from "./ColumnLabel";
import { useSelector } from "react-redux";

const BaseRadioComponent = (props) => {
  const { formState } = useFormContext();
  const { fieldsVariant } = useSelector((state) => state.customization);
  const {
    name,
    field,
    options,
    isReadOnly,
    label,
    labelWidth,
    valueName,
    labelName,
    disabled,
    rowDirection = false,
    optionAll = false,
    changeFn = () => {},
    variant = fieldsVariant ?? "standard",
    ...other
  } = props;

  const { onChange, value } = field;
  const { errors } = formState;

  return (
    <Stack direction="column" spacing={1}>
      {/* {variant === "standard" && (
        <>
          {rowDirection ? (
            <RowLabel label={label} labelWidth={labelWidth} />
          ) : (
            <ColumnLabel label={label} />
          )}
        </>
      )} */}
      {label}
      {/* Responsive Radio Buttons */}
      <RadioGroup
        name={name}
        value={value || ""}
        onChange={(e) => {
          onChange(e);
          changeFn(e);
        }}
        row={false} // Disable the default row behavior from MUI
      >
        <Grid container spacing={2} wrap="wrap">
          {optionAll && (
            <Grid item>
              <FormControlLabel
                key="all"
                value=""
                control={<Radio disabled={isReadOnly || disabled} />}
                label={<em>All</em>}
              />
            </Grid>
          )}
          {Array.isArray(options) ? (
            options.map((option) => (
              <Grid item key={valueName ? option[valueName] : option.Value}>
                <FormControlLabel
                  value={valueName ? option[valueName] : option.Value}
                  control={<Radio error disabled={isReadOnly || disabled} />}
                  label={labelName ? option[labelName] : option.Label}
                />
              </Grid>
            ))
          ) : (
            <div>No options available...</div>
          )}
        </Grid>
      </RadioGroup>
      {errors[name] && (
        <FormHelperText error>{errors[name]?.message}</FormHelperText>
      )}
    </Stack>
  );
};

const RadioInput = (props) => {
  const { control } = useFormContext();
  const {
    name,
    rules,
    label,
    labelWidth,
    options,
    changeFn = () => {},
    ...other
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={other.defaultValue}
      {...other}
      render={(props) => (
        <BaseRadioComponent
          name={name}
          options={options}
          label={label}
          changeFn={changeFn}
          {...props}
        />
      )}
    />
  );
};

export default RadioInput;
