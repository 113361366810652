import GsisCallback from "pages/Authentication/Register/GsisCallback";
import RegisterStep1 from "pages/Authentication/Register/RegisterStep1";
import RegisterStep2 from "pages/Authentication/Register/RegisterStep2";
import RegisterStep3 from "pages/Authentication/Register/RegisterStep3";
import { Navigate } from "react-router-dom";
import MinimalLayout from "src/layout/MinimalLayout";
import Login from "../pages/Authentication/Login";
import Error from "../pages/Error";

const AuthenticationRoutes = () => {
  return [
    {
      path: "/",
      element: <MinimalLayout />,
      children: [
        { path: "/api/auth/GsisCallback", element: <GsisCallback /> },
        { path: "/login", element: <Login /> },
        { path: "/register", element: <RegisterStep1 /> },
        { path: "/amka-login", element: <RegisterStep2 /> },
        { path: "/registration-form", element: <RegisterStep3 /> },
        { path: "/error", element: <Error /> },
        {
          path: "",
          element: <Navigate to="/login" />,
        },
        {
          path: "*",
          element: <Navigate to={`/login`} />,
        },
      ],
    },
  ];
};

export default AuthenticationRoutes;
