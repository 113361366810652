import DynamicDialog from "./DynamicDialog";

const ResponseHandlingDialog = (props) => {
  const {
    title,
    description,
    open,
    onPositive,
    onNegative,
    positiveDescr,
    negativeDescr,
    type = "warning",
    actionIcon,
    cancelIcon,
    cancelColor,
  } = props;
  return (
    <DynamicDialog
      open={open}
      onClose={onNegative}
      onAccept={onPositive}
      type={type}
      meta={{
        title,
        description,
        action: positiveDescr,
        cancel: negativeDescr,
        actionIcon,
        cancelIcon,
        cancelColor,
      }}
    />
  );
};

export default ResponseHandlingDialog;
