import {
  ArrowLeft,
  Cancel,
  Close,
  Done,
  Home,
  Send,
} from "@mui/icons-material";
import { Alert, Button, Collapse, Grid, IconButton } from "@mui/material";
import { registerForm } from "apis/controllers/register";
import ResponseHandlingDialog from "qubCommon/Dialogs/ResponseHandlingDialog";
import { TextInput } from "qubCommon/Inputs";
import RadioInput from "qubCommon/Inputs/RadioInput";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { createMarkup } from "util/createMarkup";
import "./index.css";

const RegisterStep3 = ({ registrationForm }) => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState("");
  const onSubmit = async (data) => {
    console.log(data);
    if (data?.instituteScheme)
      data.instituteScheme = data.instituteScheme.toString();
    if (data?.comments === "") data.comments = null;
    const res = await registerForm(data);
    if (res?.status === "success") {
      setSucceeded(true);
    } else {
      setError(res?.message);
    }
  };

  const onCancel = () => setOpenCancelDialog(true);
  if (succeeded)
    return (
      <div className="success-register-card">
        <div className="success-register-header">
          <div className="success-register-image">
            <Done />
          </div>
          <div className="success-register-content">
            <span className="success-register-title">
              Επιτυχής Υποβολή Αιτήματος
            </span>
            <p className="success-register-message">
              Το αίτημα εγγραφής σας στην πλατφόρμα έχει προωθηθεί.
            </p>
            <p className="success-register-message">
              Σας έχει αποσταλεί ένα μήνυμα ηλεκτρονικού ταχυδρομείου στο email
              που συμπληρώσατε, με τα στοιχεία που υποβάλλατε.
            </p>
            <div className="success-register-actions">
              <Button
                onClick={() => navigate("./home")}
                startIcon={<Home />}
                variant="contained"
                sx={{ color: "#fefefe", fontSize: "1rem" }}
              >
                Μετάβαση στην Αρχική
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="applications-container">
      <ResponseHandlingDialog
        title="Ακύρωση"
        description="Τα στοιχεία που συμπληρώσατε δεν θα αποσταλούν. Θέλετε να μεταβείτε στην Αρχική Σελίδα;"
        open={openCancelDialog}
        onPositive={() => navigate("/home")}
        onNegative={() => setOpenCancelDialog(false)}
        negativeDescr="Συνέχεια συμπλήρωσης"
        positiveDescr="Μετάβαση στην Αρχική"
        actionIcon={<Home />}
        cancelIcon={<ArrowLeft />}
        cancelColor="secondary"
      />
      <Collapse in={!!error}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError("");
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          variant="filled"
          severity="error"
        >
          <div dangerouslySetInnerHTML={createMarkup(error)} />
        </Alert>
      </Collapse>
      <FormProvider {...registrationForm}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1 style={{ fontWeight: 500 }}>
              Εγγραφή Νέου Χρήστη για την Υποβολή Αίτησης Προγράμματος ΜΗΔ
            </h1>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput name="lastName" label="Επώνυμο" disabled />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput name="firstName" label="Όνομα" disabled />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput name="fatherName" label="Πατρώνυμο" disabled />
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextInput name="afm" label="ΑΦΜ" disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput name="amka" label="ΑΜΚΑ" disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <RadioInput
              labelWidth={260}
              rowDirection
              name="instituteScheme"
              label="Φορέας ή Ένωση Φορέων"
              options={[
                { Value: "Φορέας", Label: "Φορέας" },
                { Value: "Ένωση Φορέων", Label: "Ένωση Φορέων" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput name="institute" label="Φορέας" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput name="address" label="Διεύθυνση" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput name="city" label="Πόλη" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput name="zip" label="ΤΚ" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput name="email" label="Email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput name="mobile" label="Κινητό Τηλ." />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput name="comments" label="Σχόλια" minRows={6} multiline />
          </Grid>
        </Grid>
      </FormProvider>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={onCancel}
          variant="contained"
          color="error"
          startIcon={<Cancel />}
        >
          Ακύρωση
        </Button>
        <Button
          variant="contained"
          onClick={registrationForm.handleSubmit(onSubmit)}
          startIcon={<Send />}
        >
          Yποβολή
        </Button>
      </div>
    </div>
  );
};

export default RegisterStep3;
