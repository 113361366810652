import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Login } from "@mui/icons-material";
import { Alert, Box, Button, Collapse, Grid, IconButton } from "@mui/material";
import { TextInput } from "qubCommon/Inputs";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getByAmkaAfm } from "util/getAmkaRegistryInfo";
import RegisterStep3 from "./RegisterStep3";
import { useRegisterValidation } from "util/validations/registration-form";
import { useYupValidationResolver } from "hooks/useYupValidationResolver";

// window.serverData = { data: { afm: "660074100" } };  // only for dev purposes

const RegisterStep2 = () => {
  const [amkaFilled, setAmkaFilled] = useState(false);
  const [data] = useState(window.serverData || {});
  const [error, setError] = useState("");

  const registerValidation = useRegisterValidation();
  const form = useForm();
  const registrationForm = useForm({
    resolver: useYupValidationResolver(registerValidation),
    criteriaMode: "all",
  });

  const onSubmit = async (data) => {
    setError("");
    console.log("hello");
    try {
      const res = await getByAmkaAfm(data?.amka, data?.afm);
      const { status } = res;
      console.log(res);
      if (status === "success") {
        const values = res?.data;
        registrationForm.reset({
          firstName: values?.firstName,
          lastName: values?.lastName,
          fatherName: values?.fatherName,
          amka: values?.amka,
          afm: values?.afm,
        });
        console.log(res?.data);
        setAmkaFilled(true);
      }
      if (status === "error") {
        setError(res?.message);
      }
      if (status === "empty") {
        setError("Παρακαλώ συμπληρώστε τα πεδία.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!amkaFilled)
    return (
      <div className="applications-container" style={{ minHeight: "70vh" }}>
        <FormProvider {...form}>
          <Box
            container
            sx={{
              padding: "28px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              background: "#fbfcfe",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: 4,
            }}
          >
            <Grid item xs={12} alignSelf="center">
              <h3
                style={{ fontSize: "1.4em", fontWeight: 500, marginBottom: 40 }}
              >
                Το TAXISNET σας πιστοποίησε, εισάγετε τον ΑΜΚΑ σας για είσοδο
              </h3>
              <Collapse in={!!error}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError("");
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  variant="filled"
                  severity="error"
                >
                  {error}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12} alignSelf="center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "40px",
                }}
              >
                <TextInput
                  name="afm"
                  label="ΑΦΜ"
                  disabled
                  defaultValue={data?.data?.afm}
                />
                <TextInput name="amka" label="ΑΜΚΑ" />
                <Button
                  sx={{ marginTop: 8 }}
                  startIcon={<Login />}
                  variant="contained"
                  onClick={form.handleSubmit(onSubmit)}
                >
                  Είσοδος
                </Button>
              </div>
            </Grid>
          </Box>
        </FormProvider>
      </div>
    );
  return <RegisterStep3 registrationForm={registrationForm} />;
};

export default RegisterStep2;
