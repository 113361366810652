import { Box, Typography } from "@mui/material";

const RowLabel = ({ label, labelWidth = 220 }) => {
  if (typeof label === "string")
    return (
      <Box sx={{ display: "flex", alignItems: "center", marginRight: 1 }}>
        <Typography
          align="right"
          sx={{
            justifyContent: "center",
            width: labelWidth,
            fontStyle: "italic",
          }}
        >{`${label}:`}</Typography>
      </Box>
    );
  else return `${label}:`;
};

export default RowLabel;
