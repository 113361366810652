import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const [show, setShow] = useState(false);

  const [data] = useState(window.serverData || {});
  console.log("data", data);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, "2000");
  }, []);

  const navigate = useNavigate();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      sx={{ height: "60vh" }}
    >
      <Grid item xs={6} sm={4}>
        {show && (
          <Stack justifyContent="center" spacing={2} alignItems="center">
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 800,
              }}
            >
              Ουπςςς! Κάτι πηγε στραβά 😱
            </Typography>
            {data?.data?.Message ? (
              <Box
                boxShadow={5}
                sx={{
                  background: "white",
                  width: "inherit",
                  p: 2,
                  borderRadius: 3,
                }}
              >
                <Typography
                  paragraph
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 800,
                    mb: 0,
                  }}
                >
                  {data?.data?.Message ?? ""}
                </Typography>
              </Box>
            ) : null}
            <Typography
              paragraph
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
                fontSize: "1rem",
                fontWeight: 800,
                mb: 0,
              }}
            >
              Επιστροφή στην αρχική σελίδα
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Error;
