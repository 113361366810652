/* QubCommon Imports */
import {
  FlexGrid,
  FlexGridCellTemplate,
  FlexGridColumn,
} from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import {
  Box,
  Button,
  Chip,
  Grid,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "src/assets/css/explorer.css";
import ToolbarExplorer from "src/qubCommon/ExplorerToolbar";
// import { useBreadcrumbs } from "hooks/useBreadcrumbs";
import { useLoading } from "src/hooks/useLoading";
import Cube from "src/qubCommon/CubeStats/Cube";
import QubGroupGrid from "src/qubCommon/Grids/QubGroupGrid";
import {
  wijmoDateFormat,
  wijmoStyleGrid,
} from "src/qubCommon/wijmoHelpers/columnMethods";
import ExplorerContextMenu from "./ExplorerContextMenu";
// import { Can } from "src/context/permissionContext";

const ExplorerGrid = (props) => {
  const {
    breadcrumbs,
    title,
    children,
    explorerDataKey,
    excelHandleClick,
    toolbarRef,
    gridRef,
    mobileGridRef,
    onSubmit,
    handleSubmit,
    form,
    explParams,
    gridInitialized,
    mobileGridLoadedRows,
    gridData,
    csvFiles,
    pdfFiles,
    excelFiles,
    initColumns,
    criteriaRef,
    critParams,
    status,
    buttons,
    newTitle,
    newUrl,
    navUrl,
    hideAddNew,
    hideAscendingRowNumber = false,
    cancelGridLoadedRows,
    valueListChips,
    setValueListChips,
    fieldSearchChips,
    setFieldSearchChips,
    records,
    showRowHeader,
    showColHeaderGroup,
    cellCssMapping,
    pageSize,
    contextMenu,
    contextMenuIds,
    handleNavigate,
    MobileViewElement,
    autoRowHeights,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [gridColumns, setGridColumns] = useState([]);
  const { setLoading } = useLoading();
  const [toolbarLoading, setToolbarLoading] = useState(true);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 480);
  const [gridSettings, setGridSettings] = useState({});
  const [isGridLoaded, setIsGridLoaded] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [page, setPage] = useState(1);

  const toolbarProps = {
    csvFiles,
    pdfFiles,
    excelFiles,
    columns: initColumns,
    criteriaRef,
    newTitle,
    newUrl,
    hideAddNew,
    showRowHeader,
    pageSize,
  };

  const theme = useTheme();

  // useBreadcrumbs({ breadcrumbs });

  /* Load data if criteria parameters change */
  useEffect(() => {
    if (critParams) {
      handleSubmit(onSubmit)();
    }
  }, [critParams]);

  /* Map api columns to wijmo columns */
  useEffect(() => {
    const helpCols = [];
    if (initColumns?.length > 0)
      initColumns.forEach((column) =>
        column.children.forEach((child) =>
          helpCols.push({
            binding: child?.fldName,
            header: child?.fldTitle,
            visible: child?.isVisible,
            format: child?.fldFormat,
            position: child?.fldPosition,
            width: child?.colWidth ?? "*",
            align: child?.colAlign ?? "center",
            multiline: child?.multiline,
          })
        )
      );

    setGridColumns(helpCols);
  }, [initColumns]);

  const handleSubmitFilters = () => {
    setPage(1);
    form.setValue("pageNumber", 1);
    handleSubmit(onSubmit)();
  };

  const handleDeleteValueListChip = (e, chip) => {
    e.preventDefault();
    toolbarRef.current?.criteriaRef?.current?.critValuesList?.forEach(
      (valueList) =>
        valueList?.children?.forEach((c) => {
          if (c?.title === chip?.title && c?.value === chip?.value)
            c.isSelected = false;
        })
    );
    setValueListChips(valueListChips.filter((c) => c?.title !== chip?.title));
  };

  const handleDeleteFieldSearchChip = (e, chip) => {
    e.preventDefault();
    toolbarRef.current?.criteriaRef?.current?.critFldsSearch?.forEach(
      (fieldSearch) => {
        if (fieldSearch?.fldSearchName === chip?.fldSearchName) {
          fieldSearch.value1 = null;
          fieldSearch.value2 = null;
        }
      }
    );
    setFieldSearchChips(
      fieldSearchChips.filter((c) => c?.fldSearchName !== chip?.fldSearchName)
    );
    handleSubmitFilters();
  };

  const gridLoadedRows = (flex) => {
    if (!cancelGridLoadedRows) {
      const columnStyles = [];
      initColumns?.forEach((group) => {
        group?.children.forEach((child) => {
          const columnStyle = {};
          columnStyle.binding = child?.fldName;
          columnStyle.colCss = child?.colCss;
          columnStyles.push(columnStyle);
        });
      });
      const cellStyles = {};
      cellCssMapping?.forEach((object) => {
        cellStyles[object?.fldName] = object?.fldNameCss;
      });
      flex.itemFormatter = (panel, r, c, cell) => {
        wijmoStyleGrid(panel, r, c, cell, columnStyles, cellStyles, flex);
        // flex.columns.forEach((col) => {
        //   if (col?.format === "date") {
        //     wijmoDateFormat(panel, r, c, cell, col.binding, false, {
        //       format: "dd/MM/yyyy",
        //     });
        //   }
        //   if (col?.format === "datetime") {
        //     wijmoDateFormat(panel, r, c, cell, col.binding, true, {
        //       format: "dd/MM/yyyy",
        //     });
        //   }
        // });
      };
      flex.autoSizeColumns();
      flex.autoSizeRows();
    }

    setIsGridLoaded(true);
  };

  const displayRowsIndicator =
    gridData && records.returned !== null && records.total !== null;
  const updateMedia = () => {
    setDesktop(window.innerWidth > 480);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // useEffect(() => {
  //   if (gridData && isGridLoaded) {
  //     convertGridToMobile(gridRef, mobileGridRef.current, initColumns);
  //   }
  // }, [gridData]);
  const handlePagination = (event, value) => {
    setPage(value);
    form.setValue("pageNumber", value);
    handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...form}>
      <Grid container sx={{ paddingLeft: 0 }}>
        <Grid container item xs={12}>
          <ToolbarExplorer
            webPageKey={explorerDataKey}
            excelHandleClick={excelHandleClick}
            mobileGridRef={mobileGridRef}
            ref={toolbarRef}
            gridRef={gridRef}
            title={t(title)}
            form={form}
            submit={handleSubmitFilters}
            toolbarProps={toolbarProps}
            setToolbarLoading={setLoading}
          >
            {buttons}
            {/* <Box boxShadow={2} sx={{ borderRadius: 50 }}>
              <Tooltip
                title={showStatistics ? "Ευρετήριο" : "Στατιστικά"}
                onClick={() => {
                  setLoading(true);
                  setShowStatistics(!showStatistics);
                  setLoading(false);
                }}
              >
                <IconButton>
                  {showStatistics ? <IconGridPattern /> : <BarChart />}
                </IconButton>
              </Tooltip>
            </Box> */}
          </ToolbarExplorer>
        </Grid>
        <Grid
          container
          spacing={2}
          flexDirection="row"
          sx={{ display: "flex", padding: 2, paddingLeft: 2, gap: 2 }}
        >
          {children}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              // sx={{ color: '#605858', background: '#ebf6fe', '&:hover': { color: '#ebf6fe', background: '#80adbf' } }}
              onClick={() => {
                form.setValue("pageNumber", 1);
                setPage(1);
                handleSubmit(onSubmit)();
              }}
            >
              {t("Search")}
            </Button>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Stack direction="row" gap={1} sx={{ flexWrap: "wrap" }}>
            {valueListChips?.map((chip, index) => (
              <Chip
                key={index}
                label={chip?.title}
                sx={{
                  background: theme.palette.background.default,
                  border: `1px solid ${theme.palette.text.secondary}`,
                }}
                onDelete={(e) => handleDeleteValueListChip(e, chip)}
              />
            ))}
            {/* {valueListChips.length >= 7 && (
              <Chip label="..." sx={{ background: "#e3ecf8" }} />
            )} */}
          </Stack>
        </Grid>
        <Grid container item xs={12} mt={1}>
          <Stack direction="row" spacing={1}>
            {fieldSearchChips?.map(
              (chip, index) =>
                index < 4 && (
                  <Chip
                    key={index}
                    label={`${chip?.title} ${t(chip.defaultOperator)} ${t(
                      chip?.value1
                    )} ${chip.value2 ? t(chip?.value2) : ""}`}
                    sx={{ background: "#e3ecf8" }}
                    onDelete={(e) => handleDeleteFieldSearchChip(e, chip)}
                  />
                )
            )}
            {fieldSearchChips.length >= 4 && (
              <Chip label="..." sx={{ background: "#e3ecf8" }} />
            )}
          </Stack>
        </Grid>
      </Grid>
      {/* {isGridLoaded && (
        <div hidden={isDesktop}>
          <MobileViewElement data={gridData} />
        </div>
      )} */}

      {false ? (
        <Cube
          columns={gridColumns}
          data={gridData?.items}
          title="Στατιστικά"
          handleNavigate={handleNavigate}
          explorerDataKey={explorerDataKey}
        />
      ) : (
        <>
          {showColHeaderGroup ? (
            <QubGroupGrid
              style={{
                height: !isDesktop ? 8 : "100%",
                minHeight: !isDesktop ? 0 : "60vh",
              }}
              initialized={gridInitialized}
              gridLoadedRows={gridLoadedRows}
              gridData={gridData?.items}
              gridColumns={initColumns}
              autoRowHeights={true}
              headersVisibility={showRowHeader ? "All" : "Column"}
            />
          ) : (
            <FlexGrid
              hidden={!isDesktop}
              style={{ height: "99%", minHeight: 200 }}
              id="explGrid"
              initialized={gridInitialized}
              loadedRows={gridLoadedRows}
              itemsSource={gridData?.items}
              autoSizeMode={1}
              isReadOnly
              headersVisibility={showRowHeader ? "All" : "Column"}
              alternatingRowStep={0}
              keyActionEnter="None"
              {...props}
              className="explorer-grid"
            >
              {gridColumns.length > 0 &&
                gridColumns.map((c, index) => (
                  <FlexGridColumn
                    key={index}
                    visible={c?.visible}
                    binding={c.binding}
                    format={c?.format}
                    width={
                      Number.isNaN(parseInt(c?.width, 10)) ||
                      c?.width?.includes("*")
                        ? c?.width
                        : parseInt(c?.width, 10)
                    }
                    header={t(c.header) ?? t(c.binding)}
                    className="explorer-column"
                    align={c?.align}
                  >
                    {c.cellTemplates?.map((ct, i) => (
                      <FlexGridCellTemplate
                        key={i}
                        cellType={ct.cellType}
                        template={(ctx) => ct.template(ctx, c)}
                      />
                    ))}
                  </FlexGridColumn>
                ))}
              <FlexGridFilter />
            </FlexGrid>
          )}
        </>
      )}
      <ExplorerContextMenu
        contextMenu={contextMenu}
        contextMenuIds={contextMenuIds}
        navUrl={navUrl}
      />
      {displayRowsIndicator ? (
        <Box display="flex" justifyContent="end">
          <Typography variant="body1" paddingTop={1}>
            {records?.filtered === records?.total
              ? `${
                  (form.watch("pageNumber") - 1) * form.watch("pageSize") + 1
                }-${
                  records?.returned +
                  (form.watch("pageNumber") - 1) * form.watch("pageSize")
                } / ${records?.total} Συνολικές Εγγραφές`
              : `${records?.returned} / ${records?.filtered} Φιλτραρισμένες εγγραφές (Συνολικά: ${records?.total})`}
          </Typography>
        </Box>
      ) : null}

      {rest.children}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div>
          <Pagination
            sx={{ width: "100%" }}
            page={page}
            onChange={handlePagination}
            count={Math.ceil(records?.filtered / form.watch("pageSize"))}
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default ExplorerGrid;
