import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm } from "react-hook-form";
import FormCheckbox from "src/qubCommon/Inputs/Checkbox";
import { AlertTitle, Button, Divider, Typography } from "@mui/material";
import {
  amendApplication,
  getAmendmentStatus,
} from "apis/controllers/applications";
import { useNavigate, useParams } from "react-router-dom";
import "./Amendment.css";
import { CssTextField } from "qubCommon/Inputs";
import {
  Skeleton,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const LoadingSkeleton = () => {
  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Skeleton sx={{ width: "50%", height: "44px" }} />
      <Skeleton sx={{ width: "40%", height: "35px" }} />
      <Skeleton sx={{ width: "100%", height: "30px" }} />
      <Skeleton sx={{ width: "100%", height: "30px" }} />
      <Skeleton sx={{ width: "100%", height: "30px" }} />
      <Skeleton sx={{ width: "100%", height: "30px" }} />
      <Skeleton sx={{ width: "100%", height: "120px" }} />
    </Box>
  );
};

export default function Amendment(props) {
  const { open, handleClose } = props;
  const ammendmentForm = useForm();
  const [loading, setLoading] = useState(true);
  const [amendmentOptions, setAmendmentOptions] = useState(null);
  const [title, setTitle] = useState("");
  const [currentStatus, setCurrentStatus] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchOptions = async () => {
    try {
      setLoading(true);
      const res = await getAmendmentStatus(id);
      console.log(res);
      setTitle(res?.data?.title);
      setCurrentStatus(res?.data?.currentStatus);
      setAmendmentOptions(res?.data?.status);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setTitle(null);
      setCurrentStatus(null);
      setAmendmentOptions(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setAmendmentOptions(null);
      setTitle(null);
      setCurrentStatus(null);
      setCheckedOption(null);
      setComments("");
      setLoading(true);
      setError(null);
    }
    if (open) {
      fetchOptions();
    }
  }, [open]);

  const handleCheckboxChange = (id) => {
    setError(null);
    setCheckedOption(id); // Set the selected option
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const handleSubmit = async () => {
    if (!checkedOption) setError("Δεν έχετε επιλέξει ενέργεια!");
    else {
      try {
        const res = await amendApplication({
          id,
          newStatusId: checkedOption,
          comments,
        });
        if (res?.status === "success") navigate(0);
        else setError(res?.message);
      } catch (e) {
        console.log(e);
        setError("Προέκυψε σφάλμα!");
      }
    }
  };

  return (
    <FormProvider {...ammendmentForm}>
      <Dialog
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <DialogTitle
              sx={{ m: 0, p: 2, fontSize: "16px" }}
              id="customized-dialog-title"
            >
              Μεταβολή Κατάστασης - {title}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent
              sx={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="form-container">
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Τρέχουσα κατάσταση αίτησης: <b>{currentStatus}</b>
                </Typography>
                <b
                  style={{
                    marginBottom: "10px !important",
                    color: "red",
                  }}
                  hidden={!error}
                >
                  {error}
                </b>
                {amendmentOptions?.length === 0 ? (
                  <div style={{ textAlign: "center", marginTop: 40 }}>
                    <b>Δεν βρέθηκαν διαθέσιμες ενέργειες!</b>
                  </div>
                ) : (
                  <>
                    <table className="actions-table">
                      <thead>
                        <tr>
                          <th>Διαθέσιμη Ενέργεια</th>
                          <th>Νέα κατάσταση αίτησης</th>
                        </tr>
                      </thead>
                      <tbody>
                        {amendmentOptions?.map((option) => {
                          return (
                            <tr>
                              <td>
                                <div className="checkbox-container">
                                  <input
                                    type="checkbox"
                                    id={option?.id}
                                    checked={checkedOption === option.id}
                                    onChange={() =>
                                      handleCheckboxChange(option.id)
                                    }
                                  />
                                  {option?.description}
                                </div>
                              </td>
                              <td>{option?.title}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="form-group">
                      <label htmlFor="comments">Σχόλια</label>
                      <CssTextField
                        multiline
                        disab
                        minRows={5}
                        fullWidth
                        fixedPadding={8}
                        onChange={handleCommentsChange}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: "white" }}
                        onClick={handleClose}
                      >
                        Επιστροφή
                      </Button>
                      <Button variant="contained" onClick={handleSubmit}>
                        Αποθήκευση
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </FormProvider>
  );
}
